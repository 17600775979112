<template>
  <select class="select-input" :name="name" ref="select" @change="onChange">
    <option v-for="(option, id) in options" :key="id" :value="option.value" :selected="elementSelect === option.value ? true : false">
      {{ option.text }}
    </option>
  </select>
</template>
<script>
export default {
  props: ["options", "selectAtt", "name", "elementSelect"],
  methods: {
    onChange: function (evt) {
      this.$emit("selected", evt.target.value);
    },
    reboot: function () {
      this.$refs.select.options[0].selected = true;
    },
    selectedOption: function (id) {
      this.$refs.select.options[id].selected = true;
    },
  },
};
</script>
<style lang="scss">
.select-input {
  background-color: $white;
}
</style>
